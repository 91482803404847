.simplifiedOverview-Wrapper {

  display: flex;
  flex-wrap: wrap;  
  height: 100%;
  width: 100%;
  overflow: auto;
  
}

 

  .simplifiedOverview-ProductElement {

    display: flex;  
    flex-direction: column;
    background-color:var(--green);
    text-align: center;
    margin: 5px;
    justify-content: space-between;
    padding: 2px;
    align-content: flex-end;
    align-items: flex-end;
    min-height: 100px;
    min-width: 180px;
    margin: 5px;
    text-align: center;
    flex: 1 0 ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;

  }


  
  .simplifiedOverview-ProductElement-OK{
    background-color: var(--green) !important;
}

.simplifiedOverview-ProductElement-WARN{
    background-color: var(--yellow) !important;
}

.simplifiedOverview-ProductElement-ERROR{
    background-color: var(--red) !important;
}

.simplifiedOverview-ProductElement-CRIT{
    background-color: var(--red) !important;
}

.simplifiedOverview-ProductElement-DOWN{
    background-color: var(--grey) !important;
}
  

.simplifiedOverview-ProductNameContainer{
  display: flex;
  background-color: var(--simplifiedOverviewProductNameContainer);
  width: 100%;  /* Gleiche Breite wie der Elterndiv */
  height: 40%; /* Feste Höhe */
  margin-top: auto;
  border-radius: 0 0 5px 5px;
}

  .simplifiedOverview-ProductName {
    display: flex;
    min-width: 1%;
    width: 100%;
    height: 100%;
    font-size: 4vw;
    word-break: break-all;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    padding-left: 2px;
    padding-right: 2px;
    color: var(--simplifiedOverviewProductTextColor);
    transform: scale(1); 
  }

  .innerSpan {
    font-size: 9vw;
  }
  

  .simplifiedOverview-ProductOrigin{
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;
    color: var(--simplifiedOverviewProductErrorTextColor);
    font-weight: bold;
    overflow: hidden;
    justify-content: center;
    font-size: 9vw;
    }

    .scaletext-wrapper{
      overflow: hidden !important;
    }

    /*
    .switch input:disabled + .slider {
      background-color: #ccc;
      cursor: not-allowed;    
    }
    */