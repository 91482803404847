.react-grid-item > .react-resizable-handle::after {
    border-right: 2px solid var(--text-color) !important;
    border-bottom: 2px solid var(--text-color) !important;
 }

.productWidgetProduct-Hidden{
    display: none !important;
}

.productWidget--configMenuWrap{
    display: flex;
    flex-direction: column;
    position: absolute;
    transition: 0.3s;
    width: 100%;    
    z-index: 500;
    height: 100%;
    background-color: var(--bg-menu);
    overflow: hidden;
}

.productWidgetConfigContent--ProductContentWrap{
    display: flex;
    flex-direction: column;
    width: 100%;  
    height: calc(100% - 250px);  
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.productWidgetConfig--DeleteWrap{
    display: flex;
    justify-content: center;
    height: 60px;
    flex-grow: 0;
    flex-shrink: 0;
}

.productWidgetHeader--DeleteBtn{
    flex: 1;
    background-color: transparent;
    margin-left: auto;
    border: none;
    color: var(--text-color); 
    font-size: 1.2rem;   
}

.productWidgetHeader--DeleteBtn:hover{
    color: var(--red); 
}

.productWidget--HeaderWrap{
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    cursor: grab;
}

.productWidgetHeader--Title{
    margin-left: 10px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: -0.5px;
    font-size: 1.4rem;    
}

.productWidget--ContentWrap{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
    
    /*
    scrollbar-width: none;
    -ms-overflow-style: none;
    */
    font-size: 1rem;
}

.productWidgetHeader--MenuBtn{
    width: 35px;
    height: 35px;
    background-color: transparent;
    margin-left: auto;
    border: none;
    color: var(--text-color);
}

.productWidgetHeader--MenuBtn:hover{
    color: var(--subtext-color);
}

.productWidget--LoadingWrap{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    flex-direction: column;
}

.productWidget--LoadingWrapText{
    margin: 0px;
    color: var(--subtext-color)
}

.productWidget--ErrorWrap{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    color: var(--subtext-color);
    text-align: center;
}

.productWidget--ContentTemplate{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
}


.productWidget-productWrap{
    display: flex;
    flex-wrap: wrap;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 35px;
    align-items: center;
    width: 100%;
    margin-top: 1px;
    margin-bottom: 1px;
}

.productWidget-productBtnWrap{
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 35px;
    align-items: center;
    width: 100%;
    padding-top: 2px;
    padding-bottom: 2px;
}

.productWidget-productBtnWrap:hover{
    background-color: var(--hover-color);
}

.productWidgetProduct-Status{
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: 10px;
    text-align: center;
    align-items: center;
}

.productWidgetProduct-Name{
    flex: 1;
    margin-left: 15px;
    margin-right: 5px;
    font-size: 1.2rem;
}

.productWidgetProduct-TestBtn{
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 8px;
    cursor: pointer;
}

.productWidgetProduct-Status-OK{
    background-color: var(--green) !important;
}

.productWidgetProduct-Status-WARN{
    background-color: var(--yellow) !important;
}

.productWidgetProduct-Status-ERROR{
    background-color: var(--red) !important;
}

.productWidgetProduct-Status-CRIT{
    background-color: var(--red) !important;
}

.productWidgetProduct-Status-DOWN{
    background-color: var(--grey) !important;
}

.productWidgetProduct-TestWrap{
    display: flex;
    flex-direction: column;
    margin-left: 55px;
    margin-bottom: 15px;
    margin-top: 5px;
    width: calc(100% - 55px);
    flex-grow: 1;
    flex-shrink: 0;
}

.productWidgetTests--HeaderWrap{
    flex-grow: 0;
    flex-shrink: 0;
    height: 20px;
    width: 100%;
    display: flex;
    align-items: center;
}

.productWidgetTestsHeader--Title{
    flex: 1; 
}

.productWidgetTestsHeader--Btn{
    display: none;
    width: 20px;
    margin-left: auto;
    text-align: center;
    margin-right: 8px;
}

.productWidgetTestsHeader--Btn:hover{
    cursor: pointer;
    color: var(--green);
}

.productWidgetTestsHeader--Btn-synthetic{
    display: block;
}

.productWidgetTestsHeader--Line{
    flex-grow: 0;
    flex-shrink: 0;
    min-width: 100%;
    height: 1px;
    background-color: var(--border-color);
}

.productWidgetTest--Container{
    flex-grow: 0;
    flex-shrink: 0;
    height: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 2px;
    margin-bottom: 1px;
    cursor: pointer;
}

.productWidgetTest--Container:hover{
    background-color: var(--hover-color);
}

.productWidgetTest--Name{
    flex: 1;
    padding-left: 2px;
}


.productWidgetTest--Symbol{
    width: 15px;
    height: 15px;
    margin-left: auto;
    text-align: center;
    margin-right: 3px;
}

.productWidgetTest--Status{
    width: 15px;
    height: 15px;
    margin-left: auto;
    text-align: center;
    margin-right: 8px;
    background-color: var(--grey);
}

.productWidgetTests--Spacer{
    flex-grow: 0;
    flex-shrink: 0;
    height: 20px;
    width: 100%;
    display: flex;
    align-items: center;    
}

.productWidgetConfig--ContentWrap{
    display: flex;
    flex-wrap: wrap;
    max-height: 100%;
    align-content: start;
    flex-grow: 1;
    flex-shrink: 1;
    font-size: 1.2rem; 
    overflow: hidden;  
    scrollbar-width: none;
    -ms-overflow-style: none;     
}


.productWidgetConfigContent--HeaderWrap{
    display: flex;
    flex-wrap: wrap;
    align-content: start;
    flex-grow: 1;
    flex-shrink: 0;
    height: 40px;
    width: 100%;
}

.productWidgetConfigContent--HeaderTitle{
    flex-grow: 1;
    flex-shrink: 1;
    height: 18px;
    padding-left: 10px;
    color: var(--text-color);
}

.productWidgetConfigContent--HeaderIcon{
    margin-left: auto;
    flex-grow: 0;
    flex-shrink: 1;
    width: 39px;
    height: 18px;
    text-align: center;
    font-size: 1.4rem;
    margin-left: auto;
    cursor: pointer;
}

.productWidgetConfigContent--LineBreak{
    flex-grow: 0;
    flex-shrink: 0;
    height: 1px;
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-top: 5px;
    background-color: var(--subtext-color);
}

.productWidgetConfigContent--HeaderSubTitle{
    flex-grow: 1;
    flex-shrink: 0;
    height: 13px;
    margin-top: 3px;
    margin-bottom: 3px;
    padding-left: 10px;
    padding-right: 5px;
    width: 100%;
    color: var(--subtext-color);
    font-size: 0.9rem;
}

.productWidgetConfigContent--ContentWrap{
    display: flex;
    flex-wrap: wrap;
    align-content: start;
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 40px;
    width: 100%;
    margin-bottom: 30px;
}

.productWidgetConfigContent--OptionWrap{
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    height: 25px;
    width: 100%;
    margin-bottom: 5px;
}

.productWidgetConfigContent--Title{
    flex: 1;
    padding-left: 10px;
    line-height: 25px;
}

.productWidgetConfigContent--Checkbox{
    margin-left: auto;
    width: 39px;
}

.productWidgetConfigContent--Switch{
    width: 28px !important;
}

.productWidgetConfigContent--ProductWrap{
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    height: 25px;
    width: 100%;
    margin-bottom: 5px;
    cursor: pointer;
}

.productWidgetConfigContent--ProductWrap:hover{
    background-color: var(--hover-color);
}

.productWidgetConfigContent--subtextColor{
    color: var(--subtext-color);
}

.productWidgetConfigContent--Icon{
    margin-left: auto;
    width: 39px;
    line-height: 25px;
    text-align: center;
    font-size: 1.4rem;
}


.tooltipStyles{
    color: #222;
    background-color: rgb(0, 247, 255);
  }


