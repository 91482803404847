.mauiWidget--ContentWrap{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    font-size: 1rem;
    margin: 0px 15px 15px 15px;
}

.mauiWidget-wrapper{
    /*background-color: var(--hover-color);*/
}

.maui-table {
  border-collapse: collapse;
}

.mauiWidget-arrow{
  margin-left: 5px;
}

.maui-tableHead {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    position: sticky;
    top: 0;
    /*background-color: var(--hover-color);*/
    background-color: var(--cloudflare-graphheader);
    z-index: 1;
}

.maui-tableCell {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.maui-Table-SearchWrapper {
  display: flex;
  flex: 1;
}

.maui-Table-SearchInput {
  margin-top: 5px;
  flex: 1;
  width: 100%; /* Fügt eine Breitenangabe hinzu */
  max-width: 150px;
  min-width: 75px;

}

.maui-Table-SearchReset {
  cursor: pointer;
  margin-top: 10px;
  margin-left: 5px;
}

.mauiWidgetHeader--Title{
  margin-left: 20px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: -0.5px;
  font-size: 1.4rem;    
}
