.graphContainerConfig--ContentWrap{
    display: flex;
    flex-wrap: wrap;
    align-content: start;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    font-size: 1.2rem; 
    /*
    overflow: auto;  
    
    scrollbar-width: none;
    -ms-overflow-style: none;     
    */
}

.graphContainerConfig--OptionWrap{
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    height: 25px;
    width: 400px;
    margin-bottom: 5px;
    margin-left: 10px;
}

.graphContainer--configTimerangeContainer{
    display: flex;
    flex-wrap: wrap;
    align-content: start;
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 40px;
    width: 100%;
    margin-bottom: 30px;
}

.graphContainerConfigContent--Title{
    padding-top: 20px;
    padding-left: 20px;
    line-height: 25px;
}

.graphContainerConfig--HeaderWrap{
    display: flex;
    flex-wrap: wrap;
    align-content: start;
    flex-grow: 0;
    flex-shrink: 0;
    height: 40px;
    width: 100%;
}

.graphContainerConfig--DeleteWrap{
    display: flex;
    justify-content: center;
    height: 60px;
    flex-grow: 0;
    flex-shrink: 0;
    overflow: auto;
}

.graphContainer--marginOverride{
    margin-right: 10px !important;
}

.graphContainer--MenuBtn{
    width: 35px;
    height: 35px;
    background-color: transparent;
    margin-left: auto;
    margin-right: 10px;
    border: none;
    color: var(--text-color);
}

.graphContainer--MenuBtn:hover{
    color: var(--subtext-color);
}

.graphContainer--DeleteAllBtn{
    width: 35px;
    height: 35px;
    background-color: transparent;
    font-size: 1.1rem;
    margin-right: 10px;
    border: none;
    color: var(--text-color);
}

.graphContainer--DeleteAllBtn:hover{
    color: var(--red);
}

.graphContainer--RefreshAllBtn{
    width: 35px;
    height: 35px;
    background-color: transparent;
    font-size: 1.3rem;
    margin-right: 10px;
    border: none;
    color: var(--text-color);
}

.graphContainer--RefreshAllBtn:hover{
    color: var(--red);
}

.graphContainer--LineBreak{
    flex-grow: 0;
    flex-shrink: 0;
    height: 1px;
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-top: 20px;
    background-color: var(--subtext-color);
}