.synmonAnalyseWrap {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 20px);
    height: calc(100% - 60px);
    backdrop-filter: blur(3px);
    z-index: 499;
  }
  
  .synmonAnalyseContent {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 90%;
    max-height: 95%;
    min-height: 95%;
    min-width: 85%;
    background-color: var(--bg-downtime);
    color: var(--text-color);
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border: 1px solid var(--border-color);    
  }

  /* ----------------- SynmonAnalyse-Uebersicht Header Start ----------------- */

.synmonAnalyseContent--Header {
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  
  .synmonAnalyseContent--Header--Title {
    margin-left: 20px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: -0.5px;
    font-size: 1.4rem;
  }
  
  .synmonAnalyseContent--Header--CloseBtn {
    flex-grow: 0;
    height: 45px;
    width: 33px;
    margin-right: 15px;
    line-height: 45px;
    font-size: 2rem;
    text-align: center;
    margin-left: auto;
    color: var(--text-color);
    cursor: pointer;
  }
  
  .synmonAnalyseContent--Header--Close--Icon {
    cursor: pointer;
    font-size: 2rem;
  }

 /* ----------------- SynmonAnalyse-Uebersicht Header Ende ----------------- */

 .synmonAnalyseContent--Content {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
    height: 100%;
    margin-top: 1px;
    margin-bottom: 1px;
    flex-direction: column;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none; 
  }

  .synmonAnalyseContent--TopWrap{
    flex-grow: 1;
    flex-basis: 500px;
    flex-shrink: 0;
    display: flex;
  }

  .synmonAnalyseContent--LeftWrap{
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 50%;
  }

  .synmonAnalyseContent--RightWrap{
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 50%;
    min-width: 50%;
  }

  .synmonAnalyseContent--Top{
    flex: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 222px;
  }

  .synmonAnalyseContent--Top-Right{
    flex: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-left: 16px;
    min-height: 222px
  }

  .synmonAnalyseContent--Bottom{
    flex: 1;
    flex-basis: 40%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .synmonAnalyseContent--Top-Product{
    flex: 0;
    padding-left: 20px;
    padding-bottom: 2px;
    font-weight: bold;
    font-size: 1.3rem;
  }

  .synmonAnalyseContent--Top-Date{
    flex: 0;
    padding-left: 20px;
    font-size: 1.2rem;
    display: flex;
    justify-content: flex-start;
    color: var(--subtext-color);
  }

  .synmonAnalyseContent--Top-Date-Label{
    flex-shrink: 1;
    flex-grow: 0;
    color: var(--red);
    margin-right: 5px;
    font-weight: bold;
  }

  .synmonAnalyseContent--Top-Date-Content{
    flex: 1;
  }

  .synmonAnalyseContent--Top-Error{
    flex: 1;
    flex-basis: 50%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 20px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 5px;
    font-size: 1.2rem;
  }

  .synmonAnalyseContent--Top-Title{
    flex: 0;
    padding-left: 5px;
    font-size: 1.3rem;
  }

  .synmonAnalyseContent--Top-Thumbnails{
    flex: 1;
    flex-basis: 60%;
    display: flex;
    overflow: auto;
  }

  .synmonAnalyseContent--Top-ThumbnailWrap{
    flex-shrink: 1;
    flex-grow: 1;
    margin: 5px;
    max-width: 25%;
    min-width: 200px;
    cursor: pointer;
    justify-content: start;
    display: flex;
    flex-direction: column;
  }

  .synmonAnalyseContent--Top-ThumbnailWrap:hover{
    background-color: var(--main-menu-hover-color);
  }

  .synmonAnalyseContent--Top-ThumbnailWrap-Active{
    background-color: var(--darkgreen);
  }

  .synmonAnalyseContent--Top-ThumbnailImage{
    flex: 1;
    width: 100%;
    height: 180px;
    background-color: var(--bg-downtime) !important;
  }

  .synmonAnalyseContent--Top-ThumbnailDate{
    flex: 1;
    font-size: 1rem;
    max-height: 15px;
    line-height: 15px;
    min-height: 15px;
    padding-left: 5px;
  }

  .synmonAnalyseContent--Bottom-Title{
    flex: 0;
    padding-left: 20px;
    padding-top: 5px;
    font-size: 1.2rem;
    color: var(--subtext-color);
  }

  .synmonAnalyseContent--Bottom-Title2{
    flex: 0;
    padding-left: 20px;
    padding-top: 5px;
    font-size: 1.2rem;
    color: var(--subtext-color);
  }

  .synmonAnalyseContent--Bottom-Image{
    flex: 1;
    margin-left: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
  }

  .synmonAnalyseContent--Bottom-Image-Right{
    flex: 1;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
  }  

  .synmonAnalyseContent--BottomWrap{
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 250px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .synmonAnalyseContent--BottomWrap-Graph{
    flex: 1;
    width: 100%;
    min-height: 250px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .synmonAnalyseContent--LoadGrapnBTN{
    height: 30px;
  }

  .hdImageStyle{
    display:block;
    width: 98%;
    height: 98%;
    object-fit:cover;
  }

  .thumbnailImageStyle{
    display:block;
    width: 100%;
    height: 100%;
    object-fit:cover;
  }