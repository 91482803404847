.downtimeAdminWrap {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 20px);
  height: calc(100% - 60px);
  backdrop-filter: blur(3px);
  z-index: 499;
}

.downtimeAdminContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 90%;
  max-height: 90%;
  min-height: 85%;
  min-width: 85%;
  background-color: var(--bg-downtime);
  color: var(--text-color);
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  border: 1px solid var(--border-color);  
}

/* ----------------- Downtime-Uebersicht Header Start ----------------- */

.downtimeAdminContent--Header {
  display: flex;
  flex: 1;
  min-height: 60px;
  max-height: 60px;
}

.downtimeAdminContent--Header--Name {
  display: flex;
  text-align: left;
  align-items: center;
  margin-left: 20px;
  flex: 50;
  font-weight: bold;
}

.downtimeAdminContent--Header--Close {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-right: 10px;
}

.downtimeAdminContent--Header--Close--Icon {
  cursor: pointer;
  font-size: 2rem;
}

/* ----------------- Downtime-Uebersicht Header End ----------------- */


.downtimeAdminContent--Content {
    display: flex;
    flex: 1;
    overflow-y: hidden;
    padding-bottom: 20px;
  }

/* ----------------- Downtime-Uebersicht Config Elemente Start ----------------- */

.downtimeAdminContent--Content--WarpWrap{
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 2;
}

.downtimeAdminContent--Content--ConfigWrap {
    flex: 2;
    margin-left: 20px;
    font-size: 1.2rem;
    /*margin-right: 10px;*/
    overflow-y: auto;
    scrollbar-width: none;
    border-radius: 3px;
  }


  .downtimeAdminContent--ConfigTable {
    flex: 1;
    border-collapse: collapse;
    width: 100%;
  }
    
        .downtimeAdminContent--ConfigTable--TableHead {
            padding-left: 5px;
            border-bottom: 5px solid var(--bg-downtime);
            background-color: var(--bg-downtime);
            position: sticky;
            top: 0;
            text-align: left;
            z-index: 1;
            font-size: 1.3rem;
        }
        
        .downtimeAdminContent--ConfigTable--Header{
          flex: 1;
        }

        .columnDummy{
          height: 20px;
        }

            .downtimeAdminContent--ConfigTable--Filter {
                display: flex;
            }

            .downtimeAdminContent--ConfigTable--SearchReset{
              display: flex;
              cursor: pointer;
              margin-top: 10px;
              margin-left: 5px;
            }
            .downtimeAdminContent--ConfigTable--SearchReset:active{
              color: var(--main-menu-button-press-color)
            }

            .downtimeAdminContent--ConfigTable--SearchInput{
                margin-top: 5px;
                flex: 1;
            }
        
            .downtimeAdminContent--ConfigTable--Body{
              max-height: calc(100% - 60px);
            }

        .downtimeAdminContent--ConfigTable--td {
            padding: 5px;
            text-align: left;
            border-bottom: 5px solid var(--bg-downtime);
        }
        .downtimeAdminContent--ConfigTable--tr{
          background-color: var(--hover-color);
        }
        .downtimeAdminContent--ConfigTable--tr:hover{
          background-color: var(--downtime-creator-hover-color);
        }
        .selected-row {
          background-color: var(--downtime-creator-hover-color);
        }
        /*.selected-row:hover{
          background-color: var(--hover-color);
        }*/
        .downtimeAdminContent--ConfigTable--td-Content{
          cursor: pointer;       
        }
        /*
            tr:nth-child(even) {
            background-color: grey;
            }
        */

        .downtimeAdminContent--Content--TableFooter{
          max-height: 8%;
          flex:1;
        }

/* ----------------- Downtime-Uebersicht Config Elemente End ----------------- */


/* ----------------- Downtime Display Block Start ----------------- */

.downtimeAdminContent--Content--DisplayWrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 20px;
  padding-left: 20px;
}

.downtimeAdminContent--Content--DisplayHeader {
  max-height: 50px;
  min-height: 50px;
  flex: 1;
  font-weight: Bold;
  font-size: 1.3rem;
}

.downtimeAdminContent--Content--DisplayHeader--CreateBtn{
    display:flex;
}

.downtimeAdminContent--Content--DisplayContent {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: none;
}

.activeDowntimeList--ElementContainer{
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  margin-bottom: 10px;
  padding: 5px;
  /*border: 1px solid white;*/
  background-color: var(--hover-color);
  border-radius: 3px;
}

.activeDowntimeList--Header{
  display: flex;
}

.activeDowntimeList--Header--TextElement{
  flex: 2;
}

.activeDowntimeList--Header--IconEdit{
  margin-right: 10px;
  cursor: pointer;
}

.activeDowntimeList--Header--IconDelete{
  margin-right: 2px;
  cursor: pointer;
}

.activeDowntimeList--StartEndeWrap{
  display: flex;
  margin-bottom: 5px;
}

.activeDowntimeList--StartElement{
  flex: 1;
}

.activeDowntimeist--CommentContainer{
  margin-top: 5px;
  margin-bottom: 5px;
}

.activeDowntimeList--TestList{
  display: flex;
  flex-direction: column;
  overflow-y:auto;
  /*border: 1px solid white;*/
  padding: 3px;
  max-height: 150px;
  background-color: var(--bg-downtime);
  border-radius: 3px;
}

.activeDowntimeList--TestList--TestLineWrapper{
  display: flex;
  margin-bottom: 5px;
}

.activeDowntimeList--TestList--TestLineElement{
  flex: 1;
}

.downtimeAdminContent--Content--CreateBtnWrap{
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 10px;
}

.downtimeAdminContent--Content--CreateBtn{
  flex: 1;
  max-width: 30%;
  font-size: 1.2rem;
  text-align: center;
  border-style: solid;
  border-width: 1px;
  padding: 5px;
  cursor: pointer;
  color: var(--main-menu-button-color);
}

.downtimeAdminContent--Content--CreateBtn:hover{
  background-color: var(--main-menu-hover-color);
  color: var(--text-color);
}
/* ----------------- Downtime Display Block End ----------------- */

/* ----------------- Create/Edit downtime Dialog Start ----------------- */
.createDowntimeWrap {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(90%);
    height: calc(90%);
    backdrop-filter: blur(3px);
    z-index: 499;
  }

  .createDowntime--Content{
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 80%;
    background-color: var(--bg-downtime);
    border-radius: 10px;
    border: 3px solid var(--grey);
    padding: 25px 50px 25px 50px;
    overflow: hidden;
    justify-content: space-between; /* neue Zeile hinzufügen */
  }

  .createDowntime--HelperContent{
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .createDowntime--HeaderWrap{
    display: flex;
    min-height: 40px;
  }

    .createDowntime--HeaderName{
      flex: 1;
      font-weight: bold;
    }

    .createDowntime--CloseBtn{
      display: flex;
      font-size: 2rem;
      cursor: pointer;
    }
    .createDowntime--CloseBtn:hover{
      color: var(--subtext-color)
    }

    .createDowntime--FormsWrapper{
      margin-bottom: 10px;
      /*border: 1px grey solid;
      border-radius: 3px;*/
    }
  /* Die wrapklasse kann eventuell weg muss mal schauen*/
  .createDowntime--DatepickerWrap{
    display: flex;
  }

  .createDowntime--Datepicker{
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .createDowntime--Datepicker--Name{
    margin-right: 5px;
    line-height: 24px;
  }

  .createDowntime--Datepicker--Element{
    margin-right: 5px;
  }

  .createDowntime--CommentWrap{
    display: flex;
    margin-top: 15px;
  }

  .createDowntime--Textarea{
    flex: 1;
  }

  .createDowntime--DowntimeList--HeaderWrap{
    display: flex;
    font-size: 1.4rem;
    font-weight: bold;
    margin-left: 5px;
  }

  .createDowntime--DowntimeList{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    font-size: 1.2rem;
    scrollbar-width: none;
  }

  .createDowntime--DowntimeList--RowWrap{
    display: flex;
    background-color: var(--hover-color);
    margin: 5px;
  }

  .createDowntime--DowntimeList--ElementWrap{
    flex: 1;
    margin: 5px;
    min-height: 28px;
  }

  .createDowntime--DowntimeList--ElementWrap--TestName{
    flex: 1;
    margin: 5px;
  }
  .createDowntime--DowntimeList--ElementWrap--Origin{
    flex: 0.25;
    margin: 5px;
  }
  .createDowntime--DowntimeList--ElementWrap--ProductName{
    flex: 0.75;
    margin: 5px;
  }

  .createDowntime--createDowntimeBtn-Wrapper{
    display: flex;
    justify-content: center;
  }

  .createDowntime--createDowntimeBtn{
    flex: 1;
    font-size: 1.3rem;
    text-align: center;
    max-width: 100px;
    margin-top: 25px;
    padding: 5px 0px 5px 0px;
    border-radius: 3px;
    text-align: center;
    border-style: solid;
    border-width: 1px;
    padding: 5px;
    cursor: pointer;
    color: var(--main-menu-button-color);
  }

  .createDowntime--createDowntimeBtn:hover{
    /*background-color: var(--create-downtime-btn-hover-color);*/
    background-color: var(--main-menu-hover-color);
    color: var(--text-color);
  }

/* ----------------- Create/Edit downtime Dialog End ----------------- */
