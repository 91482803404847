.cloudflareConfigBtn{
    width: 130px;
    height: 40px;
    margin: 10px;
}

.clousflareConfigActive{
    background-color: #cae6a3;
}

.cloudflareWidget--ContentWrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    height: 100%;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    font-size: 1rem;
}

.cloudflareGraphWidgetContainer{
    display: flex;
    position: relative;
    width: 320px;
    height: 170px;
    margin: 5px;
    background-color: var(--cloudflare-graphBG);
}

.cloudflareGraphContainerHeader {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 322px;
	height: 20px;
	overflow: hidden;
	background-color: var(--cloudflare-graphheader);
}

.cloudflareGraphContainerHeaderCaption {
	position: absolute;
	left: 3px;
	top: 0px;
	width: 290px;
	height: 20px;
	line-height: 20px;
	overflow: hidden;
	font-size: 11px;
	font-weight: bold;
	overflow: hidden;
	color: var(--text-color);
}

.cloudflareGraphContainerHeaderSUM {
	position: absolute;
	left: 183px;
	top: 0px;
	width: 110px;
	height: 20px;
	line-height: 20px;
	overflow: hidden;
	font-size: 11px;
	overflow: hidden;
	color: var(---text-color);
}

.cloudflareSUM {
	margin-right: 3px;
	overflow: hidden;
	width: 52px;
	height: 20px;
	float: left;
	cursor: help;
}

.cloudflareGraphContainerHeaderBTN {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 20px;
	height: 20px;
	line-height: 20px;
	overflow: hidden;
	font-size: 12px;
	text-align: center;
	font-weight: bold;
	color: var(--text-color);
	cursor: pointer;
}

.cloudflareGraphContainerHeaderBTN:hover{
    color: var(--green);
}

.cloudflareGraphContainerContent {
	display: flex;
	position: absolute;
	left: 0px;
	top: 20px;
	width: 322px;
	height: 150px;
	overflow: hidden;
	align-items: center;
}

.cloudflare--ErrorWrap {
	display: flex;
	flex: 1 1;
	align-items: center;
	justify-content: center;
	font-size: .8rem;
	color: var(--subtext-color);
	text-align: center;
}

.cloudflareWidgetHeader--RefreshBtn{
    width: 35px;
    height: 35px;
    background-color: transparent;
    border: none;
	margin-left: 10px;
    color: var(--text-color);
}

.cloudflareWidgetHeader--RefreshBtn:hover{
    color: var(--subtext-color);
}