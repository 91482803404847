.highcharts-label text{
    font-size: 1.2em !important;
}


.singleGraphContainer{
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 300px;
    overflow: auto;
    margin-bottom: 15px;
    margin-left: 10px;
    margin-right: 10px;
    max-width: 100%;
}

.singleGraph--HeaderContainer{
    display: flex;
    flex-shrink: 1;
    height: 45px;
    width: 100%;
    overflow: hidden;
}

.singleGraph--ContentContainer{
    display: flex;
    flex: 1;
    width: 100%;

    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-shrink: 1;
    min-width: 0;
    flex-basis: 50%;
}

.singleGraph--HeaderTextContainer{
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100%;
    max-width: 300px;
}

.singleGraph--HeaderTextProduct{
    display: flex;
    flex: 1;
    min-width: 100%;
    padding-left: 5px;
    align-items: center;
    color: var(--text-color);
    font-size: 1.3rem;
    font-weight: bold;
}

.singleGraph--HeaderTextTest{
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 100%;
    padding-left: 5px;
    color: var(--subtext-color);
    font-size: 1.2rem !important;
}

.singleGraph--HeaderMiddle{
    flex-grow: 1;
    height: 45px;
    margin-left: auto;
    color: var(--text-color);
}

.singleGraph--HeaderBtn{
    flex-grow: 0;
    height: 45px;
    width: 33px;
    margin-right: 5px;
    line-height: 45px;
    font-size: 1.4rem;
    text-align: center;
    margin-left: auto;
    color: var(--text-color);
    cursor: pointer;
}

.singleGraph--HeaderBtn:hover{
    color: var(--red);
}

.singleGraph--HeaderToolBtn{
    flex-grow: 0;
    height: 45px;
    width: 33px;
    margin-right: 5px;
    line-height: 45px;
    font-size: 1.4rem;
    text-align: center;
    margin-left: auto;
    color: var(--text-color);
    cursor: pointer;
}

.singleGraph--HeaderToolBtn:hover{
    color: var(--main-menu-button-color);
}

.ticketModalStyle{

}

.ticketModalInput{
    width: 100%;
    height: 25px;
    font-size: 13px;
    text-align: left;
    border: 1px solid #929292;
    border-radius: 2px;
    margin-top: 5px;    
}

.ticketModalArea{
    width: 100%;
    font-size: 13px;
    text-align: left;
    border: 1px solid #929292;
    border-radius: 2px;
    height: 75px;
    padding: 7px 1px;
    box-sizing: border-box;
    font-family: inherit;
    background-color: #ffffff;    
}

.ticketModalRadio{
    margin-bottom: 10px;
}

.ticketModalStaticContent{

}