.contentWrap {
    flex: 1;
    overflow: auto;
    background-color: var(--bg-dark);
    color: var(--text-color);
    margin-right: 35px;
    scrollbar-width: none;
    -ms-overflow-style: none;   
}

.fakeWidget {
    flex: 1;
    color: black;
    margin: 10px;
}

.gridItemCustomLook{
    background-color: var(--bg-widget);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border: 1px solid var(--border-color);
    border-radius: 4px;
}

.widgetContentWrap{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}